export enum BiReferral {
  ManageMenusButton = 'Ooi_Manage_Menus_button',
  ManageMenusGfpp = 'Ooi_Manage_Menus_GFPP',
  GoToDashboard = 'Ooi_Go_To_Dashboard',
}

export const addReferralToUrl = (url: string, queryReferralInfo?: BiReferral) => {
  if (queryReferralInfo) {
    const params = new URLSearchParams({ referralInfo: queryReferralInfo }).toString();
    const urlWithReferalInfo = `${url}?${params}`;
    return urlWithReferalInfo;
  }
  return url;
};
