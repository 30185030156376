import type { FlowEditorSDK, TFunction } from '@wix/yoshi-flow-editor';
import { PagesPanelTabType, type ComponentRef, type PageRef } from '@wix/platform-editor-sdk';
import { setPagesState } from './createPage';
import { MENUS_SHOWCASE_APP_DEF_ID, PARENT_APP_DEF_ID } from 'root/utils/consts';
import type { ComponentStructure, PageData } from './consts';
import { PAGE_DATA, responsiveLayouts, widgetLayout } from './consts';

const createSectionDefinition = (components: ComponentStructure[]): ComponentStructure => {
  return {
    type: 'Container',
    components,
    componentType: 'responsive.components.Section',
    layouts: {
      componentLayout: {
        type: 'ComponentLayout',
        height: {
          type: 'auto',
        },
        minHeight: {
          type: 'px',
          value: 500,
        },
      },
      itemLayout: {
        id: '',
        type: 'GridItemLayout',
        gridArea: {
          rowStart: 1,
          columnStart: 1,
          rowEnd: 2,
          columnEnd: 2,
        },
        alignSelf: 'stretch',
        justifySelf: 'stretch',
      },
      containerLayout: {
        type: 'GridContainerLayout',
        rows: [
          {
            type: 'fr',
            value: 1,
          },
        ],
        columns: [
          {
            type: 'fr',
            value: 1,
          },
        ],
      },
      type: 'SingleLayoutData',
    },
  };
};

const addWidgetAsPage = async ({
  editorSDK,
  appDefId,
  pageData,
  pageTitle,
  shouldAddMenuItem = false,
  shouldNavigateToPage,
  tpaAppId,
  isResponsive,
}: {
  editorSDK: FlowEditorSDK;
  appDefId: string;
  pageData: PageData;
  pageTitle: string;
  shouldAddMenuItem?: boolean;
  shouldNavigateToPage: boolean;
  tpaAppId: number;
  isResponsive: boolean;
}): Promise<PageRef> => {
  const { pageId, widgetId, pageUriSEO } = pageData;

  const tpaWidget: ComponentStructure = {
    componentType: 'wysiwyg.viewer.components.tpapps.TPASection',
    data: {
      applicationId: `${tpaAppId}`,
      widgetId,
      appDefinitionId: appDefId,
      type: 'TPA',
    },
    skin: 'wysiwyg.viewer.skins.TPASectionSkin',
    layout: widgetLayout,
    layouts: isResponsive ? responsiveLayouts : undefined,
  };

  const pageRefPromise = await editorSDK.document.transactions.runAndWaitForApproval('token', async () => {
    const pageRef = await editorSDK.document.pages.add('', {
      title: pageTitle,
      definition: {
        id: '',
        type: 'Page',
        componentType: 'mobile.core.components.Page',
        components: [
          isResponsive
            ? createSectionDefinition([tpaWidget])
            : {
                anchor: {
                  id: '',
                  type: 'AnchorInfo',
                  name: 'Menus',
                },
                componentType: 'wysiwyg.viewer.components.ClassicSection',
                components: [tpaWidget],
                design: {
                  type: 'MediaContainerDesignData',
                  background: {
                    type: 'BackgroundMedia',
                  },
                },
              },
        ],
        data: {
          appDefinitionId: appDefId,
          managingAppDefId: PARENT_APP_DEF_ID,
          tpaPageId: pageId,
          pageUriSEO,
          tpaApplicationId: tpaAppId,
        },
      },
      shouldAddMenuItem,
      shouldNavigateToPage,
    });

    return pageRef;
  });

  return pageRefPromise;
};

export const addAppPage = async ({
  editorSDK,
  appDefId,
  pageData,
  pageTitle,
  shouldAddMenuItem = false,
  shouldNavigateToPage,
  tpaAppId,
  isResponsive,
}: {
  editorSDK: FlowEditorSDK;
  appDefId: string;
  pageData: PageData;
  pageTitle: string;
  shouldAddMenuItem?: boolean;
  shouldNavigateToPage: boolean;
  tpaAppId: number;
  isResponsive: boolean;
}): Promise<{ pageRef: PageRef; widgetRef?: ComponentRef }> => {
  const ppPageRef = await addWidgetAsPage({
    editorSDK,
    appDefId,
    pageData,
    pageTitle,
    shouldAddMenuItem,
    shouldNavigateToPage,
    tpaAppId,
    isResponsive,
  });

  await setPagesState({ editorSDK });

  return { pageRef: ppPageRef };
};

export const addMenuOOIPage = async ({
  editorSDK,
  t,
  tpaAppId,
  isResponsive,
}: {
  editorSDK: FlowEditorSDK;
  t: TFunction;
  tpaAppId: number;
  isResponsive: boolean;
}) => {
  const pageTitle = t(PAGE_DATA.title);
  const { pageRef } = await addAppPage({
    editorSDK,
    appDefId: MENUS_SHOWCASE_APP_DEF_ID,
    pageData: PAGE_DATA,
    pageTitle,
    shouldAddMenuItem: true,
    shouldNavigateToPage: true,
    tpaAppId,
    isResponsive,
  });

  await editorSDK?.editor.openPagesPanel('', {
    pageRef,
    renameEnabled: true,
    initialSettingsTabType: PagesPanelTabType.PageInfo,
  });
};
