import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { openManageMenuDashboardPanel } from 'root/utils/OOI/panels';
import type { BiReferral } from 'root/utils/OOI/bi';

export const privateOOIExports = (editorSDK: FlowEditorSDK) => {
  return {
    openManageMenuDashboardPanel: async (queryReferralInfo?: BiReferral) => {
      await openManageMenuDashboardPanel(editorSDK, queryReferralInfo);
    },
  };
};
