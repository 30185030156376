import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { ComponentRef, PageRef } from '@wix/platform-editor-sdk';
import { MENUS_SHOWCASE_APP_DEF_ID, PARENT_APP_DEF_ID } from 'root/utils/consts';
import { PAGE_DATA, type PageData } from './consts';

const addWidgetAsPage = async ({
  editorSDK,
  appDefId,
  pageData,
}: {
  editorSDK: FlowEditorSDK;
  appDefId: string;
  pageData: PageData;
}): Promise<PageRef> => {
  const { pageId, widgetId } = pageData;

  const pageRefPromise = await editorSDK.document.transactions.runAndWaitForApproval('token', async () => {
    // @ts-expect-error
    const { pageRef } = await editorSDK.document.tpa.add.component('', {
      componentType: editorSDK.document.tpa.TPAComponentType.Page,
      appDefinitionId: appDefId,
      managingAppDefId: PARENT_APP_DEF_ID,
      widget: {
        widgetId,
        shouldNavigate: true,
      },
      page: {
        isHidden: false,
        pageId,
      },
    });
    return pageRef;
  });

  return pageRefPromise;
};

export const setPagesState = async ({ editorSDK }: { editorSDK: FlowEditorSDK }) => {
  const applicationPages = await editorSDK.document.pages.data.getAll('');

  const dupPages = applicationPages.filter((page) => page.tpaPageId === PAGE_DATA.pageId);

  editorSDK.document.pages.setState('', {
    state: {
      [PAGE_DATA.pageId]: dupPages.map((page) => ({ id: page.id })) as PageRef[],
    },
  });
};

const createAppPage = async ({
  editorSDK,
  appDefId,
  pageData,
}: {
  editorSDK: FlowEditorSDK;
  appDefId: string;
  pageData: PageData;
}): Promise<{ pageRef: PageRef; widgetRef?: ComponentRef }> => {
  const ppPageRef = await addWidgetAsPage({
    editorSDK,
    appDefId,
    pageData,
  });

  await setPagesState({ editorSDK });

  return { pageRef: ppPageRef };
};

export const createMenuOOIPage = async ({ editorSDK }: { editorSDK: FlowEditorSDK }) => {
  const { pageRef } = await createAppPage({
    editorSDK,
    appDefId: MENUS_SHOWCASE_APP_DEF_ID,
    pageData: PAGE_DATA,
  });

  try {
    await editorSDK.document.pages.navigateTo('', { pageRef });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Failed to navigate to menus page', e);
  }
};
